import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

import '../stylesheets/bootstrap.css';
import '../stylesheets/style.css';
import '../stylesheets/responsive.css';
import '../stylesheets/colors/color1.css';
import '../stylesheets/animate.css';
import '../icon/apple-touch-icon-48-precomposed.png';
import '../icon/apple-touch-icon-32-precomposed.png';
import '../icon/favicon.png';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {hover: [false, false]};
  }
  mouseEnter(event) {
    var idx = event.currentTarget.dataset['id'];
    var tmp = this.state.hover;
    tmp[idx] = true;
    this.setState({hover: tmp});
  }
  mouseOut(event) {
    var idx = event.currentTarget.dataset['id'];
    var tmp = this.state.hover;
    tmp[idx] = false;
    this.setState({ hover: tmp });
  }
  render() {
    return (
      <div className="boxed">       
            <Header/>
            <div className="page-title parallax parallax1">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">                    
                            <div className="page-title-heading">
                                <h1 className="title">About us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="flat-row pad-top60px pad-bottom90px">
                <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                            <div className="flat-iconbox flat-icon-left style1">
                                <div className="content">
                                  <p>
                                    <strong><span className="color-blue">CoEdge ... </span>Your trusted partner to get an <span className="color-blue">Edge </span>over competition.</strong>
                                    </p><br/>
                                    <p>
                                        At CoEdge, commitment meets excellence, a rare combination of qualities which enables us to help our customers achieve cost effective and techno-commercial solutions for all their needs. Icing on the cake is the speed at which we deliver error free solutions – each time.
                                        <br/><br/>

                                        Use of high-end technology and processes have revolutionized our offerings. Our range of BIM services offer clients a realistic cost saving upto 40-45% for their design and construction projects.<br/><br/>

                                        Our software customisation / development solutions help our customers boost their productivity with unique solution offerings. Our practical and logical approach to the tailored requirements enable us to deliver more than client’s expectations – each time. Variety of services we offer covers almost all widely used CAD/CAM platforms.
                                    </p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="flat-row pad-top60px pad-bottom90px vision-mission-bg">
                <div className="container">
                    <div className="row">
                     <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <div className="flat-iconbox flat-icon-left style1">                    
                              <h3>Our Mission</h3>
                                <div className="icon">
                                    <img src={require("../images/about/cad-1.jpg")} alt="Services Logo"/>
                                </div>
                                <div className="content">
                                    <p>To deliver unparalleled techno-economic solutions to our clients globally by doing it right first time, every time.</p> 
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <div className="flat-iconbox flat-icon-left style1">                    
                             <h3>Our Vision</h3>
                                <div className="icon">
                                    <img src={require("../images/about/cad-2.jpg")} alt="Services Logo"/>
                                </div>
                                <div className="content">
                                    <p>To surpass customers expectations and become a leading BIM service and software development solutions provider company with global footprint. Also to create an environment within the company which fosters creativity, innovation and continuous learning and personal growth.</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
            <div className="flat-row row-contact-us">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="flat-contact-us">
                                <h1>Your Trusted Partner for Growth</h1>
                                <p>Passion | Experience | Skills</p>
                            </div>
                            <NavLink to="about" className="btn btn-primary btn-stripe">About Us</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
  }
}

export default About;
