import React from 'react';
import { NavLink } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

const Error = () => {
	return(
		<div className="boxed">
		  <Header/>
		  <div className="page-title parallax parallax1">
		    <div className="overlay"></div>
		    <div className="container">
		      <div className="row">
		        <div className="col-md-12">
		          <div className="page-title-heading">
		            <h1 className="title">404 | Page Not Found!</h1>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		  <section className="flat-row pad-top90px pad-bottom70px">
		    <div className="container">
		      <div className="row">
		        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
		          <div className="row">
		            <div className="col-md-12">
		              <h3 className="title-contact text-left">Error <span>Path Does Not Exist!!</span></h3>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </section>
		  <div className="flat-row row-contact-us">
		    <div className="container">
		      <div className="row">
		        <div className="col-md-12">
		          <div className="flat-contact-us">
		            <h1>Your Trusted Partner for Growth</h1>
		            <p>Passion | Experience | Skills</p>
		          </div>
		          <NavLink to="/about" className="btn btn-primary btn-stripe">About Us</NavLink>
		        </div>
		      </div>
		    </div>
		  </div>
		  <Footer/>
		</div>
	);
};

export default Error;