import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';


class Footer extends Component {
	constructor(props) {
        super(props);
        this.onTopClick = this.onTopClick.bind(this);
        this.state = {active: false};
    }
    onTopClick() {
    	$("html, body").animate({scrollTop: 0}, 1000);
    }
    render() {
        return (
          <div>
            <footer className="footer">
                <div className="footer-widgets">
                    <div className="container">
                        <div className="row"> 
                            <div className="col-md-4">  
                                <div className="widget widget_text">
                                    <img src={require("../images/ftr-logo.png")} alt="CoEdge Logo"/> 
                                    <div className="social">
                                        <a href="https://twitter.com/coedgedotnet" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i> </a>
                                        <a href="https://www.linkedin.com/company/coedge-solutions-pvt-ltd/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i> </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">  
                                <div className="widget widget-categories">
                                    <h4 className="widget-title">Our Services</h4>
                                    <ul className="ftr-list">
                                        <li><NavLink to="/services"><span><i className="fa fa-check"></i></span> <span>AEC CAD Modeling services</span></NavLink></li>
                                        <li><NavLink to="/services"><span><i className="fa fa-check"></i></span> <span>Engineering (AEC and Manufacturing) Software Development and Customization.</span></NavLink></li>
                                        <li><NavLink to="/services"><span><i className="fa fa-check"></i></span> <span>Remote Software Development &amp; Quality Assurance Teams.</span></NavLink></li>
                                    </ul>
                                </div>  
                            </div>
                            <div className="col-md-3">  
                                <div className="widget widget-text">                            
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="textwidget">                                        
                                        <p><span>Email :</span><br /> <a href="mailto:info@coedge.net" >info@coedge.net</a></p>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        
            <div className="bottom">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="copyright"> 
                                <p>© Copyright Coedge {new Date().getFullYear()}. All Rights Reserved.
                                </p>
                            </div>                   
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default Footer;
