import React from 'react';
import { NavLink } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Slider from './Slider';

import '../stylesheets/bootstrap.css';
import '../stylesheets/style.css';
import '../stylesheets/responsive.css';
import '../stylesheets/colors/color1.css';
import '../stylesheets/animate.css';
import '../icon/apple-touch-icon-48-precomposed.png';
import '../icon/apple-touch-icon-32-precomposed.png';
import '../icon/favicon.png';


const Home = () => {
	return(
		<div className="boxed">
			<Header/>
			<Slider/>
	        <section className="flat-row pad-top80px main-content blog-posts">
	            <div className="container">
	                <div className="row">
	                    <div className="col-md-12">
	                        <div className="title-section">
	                            <h1 className="title">Our Services</h1>
	                            <p className="title-small">Engineering Interoperability</p>
	                        </div>
	                    </div>
	                </div>
	                <div className="row">
	                    <div className="col-md-12">
	                        <div className="row">
	                        	<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
	                                <div className="services-box clearfix text-left layout_outline">
	                                    <div className="box">
	                                        <div className="feature-image absolute-skew">
	                                        	<NavLink to="/services" className="entry-thumbnail-overlay"><img src={require("../images/about/service-1.jpg")} alt="Services Logo"/></NavLink>
	                                        </div>
	                                            
	                                        <div className="service-box-content">
		                                        <h4 className="service-box-title"><NavLink to="/services">AEC CAD Modeling services</NavLink></h4>
	                                        	<p className="service-description"><span style={{color:'#57bde6'}}>CAD + BIM Services</span></p>
	                                        	<p>We function as your technical partner and provide you dedicated resources for CAD modelling services for A/E/C firms.</p>
	                                        	<p>We offer below services in BIM:</p><br/>
	                                            <ul className="service-box-list">
	                                                <li><i className="fa fa-check"></i> 3D BIM Modelling Services</li>
	                                                <li><i className="fa fa-check"></i> SCAN to BIM (Point Cloud to BIM)</li>
	                                            </ul>
	                                            <NavLink className="btn-read-more accent-color heading_font" to="/services">Read more <i className="fa fa-long-arrow-right"></i></NavLink>
	                                        </div>
	                                        
	                                    </div>
	                                </div>
	                            </div>
	                            
	                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
	                                <div className="services-box clearfix text-left layout_outline">
	                                    <div className="box">
	                                        <div className="feature-image absolute-skew">
	                                        	<NavLink className="entry-thumbnail-overlay" to="/services"><img src={require("../images/about/service-2.jpg")} alt="Services Logo"/></NavLink>
	                                        </div>
	                                            
	                                        <div className="service-box-content">
		                                        <h4 className="service-box-title"><NavLink to="/services">Engineering (AEC and Manufacturing) Software Development and Customization</NavLink></h4>
	                                        	<p className="service-description"><span style={{color:'#57bde6'}}>CAD Software Development</span></p>
	                                        	<p>Providing software services in the engineering sector is CoEdge’s core USP.</p><br/>
	                                            
	                                            <ul className="service-box-list">
	                                                <li><i className="fa fa-check"></i> Software Customization and Plug-ins</li>
	                                                <li><i className="fa fa-check"></i> Engineering Interoperability</li>
	                                            </ul>
	                                        	<NavLink className="btn-read-more accent-color heading_font" to="/services">Read more <i className="fa fa-long-arrow-right"></i></NavLink>
	                                        </div>
	                                        
	                                    </div>
	                                </div>
	                            </div>
	                            
	                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
	                                <div className="services-box clearfix text-left layout_outline">
	                                    <div className="box">
	                                        <div className="feature-image absolute-skew">
	                                        	<NavLink className="entry-thumbnail-overlay" to="/services"><img src={require("../images/about/service-3.jpg")} alt="Services Logo"/></NavLink>
	                                        </div>
	                                            
	                                        <div className="service-box-content">
		                                        <h4 className="service-box-title"><NavLink to="/services">Remote Software Development &amp; Quality Assurance Teams</NavLink></h4>
	                                        	<p className="service-description"><span style={{color:'#57bde6'}}>CAD Software Quality Assurance & Automation</span></p><br/>
	                                            
	                                            <ul className="service-box-list">
	                                                <li><i className="fa fa-check"></i> Eliminate the Task</li>
	                                                <li><i className="fa fa-check"></i> Dividing Your Project</li>
	                                                <li><i className="fa fa-check"></i> Always Improving</li>
	                                            </ul>
	                                            <NavLink to="/services" className="btn-read-more accent-color heading_font">Read more <i className="fa fa-long-arrow-right"></i></NavLink>
	                                        </div>
	                                        
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </section>
         
	        <div className="flat-row row-contact-us">
	            <div className="container">
	                <div className="row">
	                    <div className="col-md-12">
	                        <div className="flat-contact-us">
	                            <h1>Your Trusted Partner for Growth</h1>
	                            <p>Passion | Experience | Skills</p>
	                        </div>
	                        <NavLink to="/about" className="btn btn-primary btn-stripe">About Us</NavLink>
	                    </div>
	                </div>
	            </div>
	        </div>

       		<Footer/>

    	</div>
	);
};

export default Home;