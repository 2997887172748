import React, { Component } from "react";
import { Carousel, CarouselCaption, CarouselInner, CarouselItem, View, Mask } from "mdbreact";
import { NavLink } from 'react-router-dom';

import '../stylesheets/slidercustom.css';

class Slider extends Component {
  render() {
    return (
      <Carousel activeItem={1} length={2} showControls={true} showIndicators={true} className="z-depth-1">
        <CarouselInner>
          <CarouselItem itemId="1">
            <View>
              <img className="d-block w-100" style={{height:'auto'}} src={require("../images/slides/slide-1.jpg")} alt="First slide" />
              <Mask overlay="black-light" />
            </View>
            <CarouselCaption>
              <img src={require("../images/ftr-logo.png")} alt="CoEdge Logo"/> 
              <p className="slide-sub-title">Your Trusted Partner to get an edge over compitition</p>
              <NavLink to="/contact" className="btn btn-primary btn-slider-contact">Contact Us</NavLink>
            </CarouselCaption>
          </CarouselItem>
          <CarouselItem itemId="2">
            <View>
              <img className="d-block w-100" style={{height:'auto'}} src={require("../images/slides/slide-2.jpg")} alt="Second slide" />
              <Mask overlay="black-strong" />
            </View>
            <CarouselCaption>
              <img src={require("../images/ftr-logo.png")} alt="CoEdge Logo" /> 
              <p className="slide-sub-title">Your Trusted Partner to get an edge over compitition</p>
              <NavLink to="/contact" className="btn btn-primary btn-slider-contact">Contact Us</NavLink>
            </CarouselCaption>
          </CarouselItem>
        </CarouselInner>
      </Carousel>
    );
  }
}

export default Slider;