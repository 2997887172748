import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

import '../stylesheets/bootstrap.css';
import '../stylesheets/style.css';
import '../stylesheets/responsive.css';
import '../stylesheets/colors/color1.css';
import '../stylesheets/animate.css';
import '../icon/apple-touch-icon-48-precomposed.png';
import '../icon/apple-touch-icon-32-precomposed.png';
import '../icon/favicon.png';

class Services extends Component {
  constructor(props) {
    super(props);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.state = {
      hover: [false,false,false,false,false,false,false,false,false,false]
    };
  }
  mouseEnter(event) {
    var idx = event.currentTarget.dataset['id'];
    var tmp = this.state.hover;
    tmp[idx] = true;
    this.setState({hover: tmp});
  }
  mouseOut(event) {
    var idx = event.currentTarget.dataset['id'];
    var tmp = this.state.hover;
    tmp[idx] = false;
    this.setState({ hover: tmp });
  }
  render() {
    return (
      <div className="boxed">        
          <Header/>
          <div className="page-title parallax parallax1">
              <div className="overlay"></div>
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">                    
                          <div className="page-title-heading">
                              <h1 className="title">Our Services</h1>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <section className="flat-row pad-top60px pad-bottom90px vision-mission-bg">
              <div className="container">
                  <div className="row  pb50 mb50 bb-grey">
                      <div className="col-md-12"> 
                          <div className="title-section">
                              <h1 className="title text-left">AEC CAD Services</h1>
                          </div>
                      </div>
                      <div className="col-md-8 col-lg-8 col-sm-7 col-xs-12">
                          <div className="flat-iconbox flat-icon-left style1">                    
                              <div className="content">
                                  <p>
                                      We function as your technical partner and provide you dedicated resources for CAD modelling services for A/E/C firms.
                                      <br/><br/>
                                      We specialize in delivering End-to-End Revit BIM solutions to our clients.<br/><br/>
                                      We offer below services in BIM
                                  </p> 

                                  <ul className="service-box-list">
                                      <li><i className="fa fa-check"></i> 3D BIM Modelling Services</li>
                                      <li><i className="fa fa-check"></i> SCAN to BIM (Point Cloud to BIM)</li>
                                  </ul>
                                  <br/>
                                  <p>
                                      We maintain level of detail (LOD 100 to LOD 500) as required, and provide BIM modelling services across all disciplines to create detailed BIM Model.
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-sm-5 col-xs-12">
                          <div className="product-image">
                              <img src={require("../images/about/service-1.jpg")} alt="Services Logo"/>
                          </div>
                      </div>
                  </div>
                  <div className="row pb50 mb50">
                      <div className="col-md-12 text-left"> 
                          <div className="title-section text-left">
                              <h1 className="title text-left">Software Development Services</h1>
                          </div>
                      </div>
                      <div className="col-md-8 col-lg-8 col-sm-7 col-xs-12">
                          <div className="flat-iconbox flat-icon-left style1">                    
                              <div className="content">
                                  <p>
                                      Providing software services in the engineering sector is CoEdge’s core USP. Our ability to understand various CAD/Engineering tools, geometry kernels, APIs and expertise in various programming languages and development environments help our clients offer global competitiveness by reducing their development and maintenance cost and enhancing their ability to quickly release newer versions of their engineering applications or products.<br/><br/>
                                      we offer our clients expertise in various domains:
                                  </p> 
                              </div>
                          </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-sm-5 col-xs-12">
                           <div className="product-image">
                              <img src={require("../images/about/service-2.jpg")} alt="Services Logo"/>
                          </div>
                      </div>
                  </div>
                  <div className="row pb50 mb20">
                      <div className="col-md-4 col-lg-4 col-sm-5 col-xs-12">
                          <div className="product-detail-box">
                              <div data-id="0" className={ this.state.hover[0] ? "flip panel" : "panel"} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseOut.bind(this)}>
                                  <div className="front">
                                      <div className="box1">
                                          <p>3D Visualization - Desktop</p>
                                      </div>
                                  </div>
                                  <div className="back">
                                      <div className="box2">
                                          <ul className="list-2" style={{paddingLeft:'20px'}}>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Hoops Visualize</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Eyeshot</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> 3D Scanner Application</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> RedSDK</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> OpenGL</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> VTK</span></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-sm-5 col-xs-12">
                          <div className="product-detail-box">
                              <div data-id="1" className={ this.state.hover[1] ? "flip panel" : "panel"} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseOut.bind(this)}>
                                  <div className="front">
                                      <div className="box1">
                                          <p>3D Visualization - Web</p>
                                      </div>
                                  </div>
                                  <div className="back">
                                      <div className="box2">
                                          <ul className="list-2" style={{paddingLeft:'20px'}}>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Three.js</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> WebGL</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> BimSync</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> RedSDK</span></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-sm-5 col-xs-12">
                          <div className="product-detail-box">
                              <div data-id="2" className={ this.state.hover[2] ? "flip panel" : "panel"} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseOut.bind(this)}>
                                  <div className="front">
                                      <div className="box1">
                                          <p>3D Visualization - Mobile</p>
                                      </div>
                                  </div>
                                  <div className="back">
                                      <div className="box2">
                                          <ul className="list-2" style={{paddingLeft:'20px'}}>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Hoops Visualize</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Cocos3D</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> OpenGL ES</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Unity</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Xamarin</span></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="row mb20">
                      <div className="col-md-12">

                          <h3 style={{background:'#f5f5f5', padding:'15px', marginBottom:'15px'}}>Software Customisation and Plug-ins</h3>
                      </div>
                  </div>
                  <div className="row pb50 mb20">
                      <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                          <div className="product-detail-box">
                              <div data-id="3" className={ this.state.hover[3] ? "flip panel" : "panel"} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseOut.bind(this)}>
                                  <div className="front">
                                      <div className="box1">
                                          <p>CAD/CAM/CAE</p>
                                      </div>
                                  </div>
                                  <div className="back">
                                      <div className="box2">
                                          <ul className="list-2" style={{paddingLeft:'20px'}}>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> AutoCAD</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Inventor</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> SolidWorks</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Creo</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Onshape</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Fusion360</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Siemens-NX</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> SolidEdge</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Rhinoceros</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Vectorworks</span></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                          <div className="product-detail-box">
                              <div data-id="4" className={ this.state.hover[4] ? "flip panel" : "panel"} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseOut.bind(this)}>
                                  <div className="front">
                                      <div className="box1">
                                          <p>Architectural</p>
                                      </div>
                                  </div>
                                  <div className="back">
                                      <div className="box2">
                                          <ul className="list-2" style={{paddingLeft:'20px'}}>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Revit</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Max</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Navis Work</span></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                          <div className="product-detail-box">
                              <div data-id="5" className={ this.state.hover[5] ? "flip panel" : "panel"} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseOut}>
                                  <div className="front">
                                      <div className="box1">
                                          <p>Animation</p>
                                      </div>
                                  </div>
                                  <div className="back">
                                      <div className="box2">
                                          <ul className="list-2" style={{paddingLeft:'20px'}}>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Maya</span></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                          <div className="product-detail-box">
                              <div data-id="6" className={ this.state.hover[6] ? "flip panel" : "panel"} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseOut.bind(this)}>
                                  <div className="front">
                                      <div className="box1">
                                        <p>Colobrative Platform</p>
                                      </div>
                                  </div>
                                  <div className="back">
                                      <div className="box2">
                                          <ul className="list-2" style={{paddingLeft:'20px'}}>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Forge</span></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="row pb50">
                      <div className="col-md-4 col-lg-4 col-sm-5 col-xs-12">
                          <div className="product-detail-box">
                              <div data-id="7" className={ this.state.hover[7] ? "flip panel" : "panel"} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseOut}>
                                  <div className="front">
                                      <div className="box1">
                                          <p>Engineering Interoperability</p>
                                      </div>
                                  </div>
                                  <div className="back">
                                      <div className="box2">
                                          <ul className="list-2" style={{paddingLeft:'20px'}}>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> DWG/DXF</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> RealDWG</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Open Design Alliance</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Hoops Exchange</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Parasolid</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> ACIS</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> CGM</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Open Cascade</span></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-sm-5 col-xs-12">
                          <div className="product-detail-box">
                              <div data-id="8" className={ this.state.hover[8] ? "flip panel" : "panel"} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseOut.bind(this)}>
                                  <div className="front">
                                      <div className="box1">
                                          <p>AR-VR development</p>
                                      </div>
                                  </div>
                                  <div className="back">
                                      <div className="box2">
                                          <ul className="list-2" style={{paddingLeft:'20px'}}>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Awe.js</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Catchoom</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Google Cloud Vision API</span></li>
                                              <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Javascript</span></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-sm-5 col-xs-12">
                          <div className="product-detail-box">
                              <div data-id="9" className={ this.state.hover[9] ? "flip panel" : "panel"} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseOut.bind(this)}>
                                  <div className="front">
                                      <div className="box1">
                                          <p>QA Testing</p>
                                      </div>
                                  </div>
                                  <div className="back">
                                      <div className="box2">
                                          <ul className="list-2" style={{paddingLeft:'20px'}}>
                                               <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Functional/Non Functional</span></li>
                                               <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Manual Automations</span></li>
                                               <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> MobileTesting</span></li>
                                               <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> SmokeSanity</span></li>
                                               <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Website</span></li>
                                               <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Tablets</span></li>
                                               <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Desktop</span></li>
                                               <li style={{textAlign:'left'}}><span ><i className="fa fa-check"></i> Mobile Devices</span></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <div className="flat-row row-contact-us">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="flat-contact-us">
                              <h1>Your Trusted Partner for Growth</h1>
                              <p>Passion | Experience | Skills</p>
                          </div>
                          <NavLink to="/about" className="btn btn-primary btn-stripe">About Us</NavLink>
                      </div>
                  </div>
              </div>
          </div>
          <Footer/>
      </div>
    );
  }
}

export default Services;
