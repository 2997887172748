import React from 'react';
import { NavLink } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

const Contact = () => {
	return(
		<div className="boxed">
		  <Header/>
		  <div className="page-title parallax parallax1">
		    <div className="overlay"></div>
		    <div className="container">
		      <div className="row">
		        <div className="col-md-12">
		          <div className="page-title-heading">
		            <h1 className="title">Contact Us</h1>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		  <section className="flat-row pad-top90px pad-bottom70px">
		    <div className="container">
		      <div className="row">
		        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
		          <div className="row">
		            <div className="col-md-12">
		              <h3 className="title-contact text-left">Contact</h3>
		            </div>
		          </div>
		          <div className="row">
		            <div className="col-md-12">
		              <aside id="gsf-info_box-5" className="contact-info widget widget-info_box">
		              	<div className="item-display-block">		
		                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
		                    <div className="info-shape accent-color background fs-23">
		                      <div className="icon"><i className="fa fa-envelope-o"></i></div>
		                    </div>
		                    <div className="info-content">
		                      <h6 className="uppercase"> Email:</h6>
		                      <p><a href="mailto:info@coedge.net" >info@coedge.net</a></p>
		                    </div>
		                  </div>
		                </div>
		              </aside>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </section>
		  <div className="flat-row row-contact-us">
		    <div className="container">
		      <div className="row">
		        <div className="col-md-12">
		          <div className="flat-contact-us">
		            <h1>Your Trusted Partner for Growth</h1>
		            <p>Passion | Experience | Skills</p>
		          </div>
		          <NavLink to="/about" className="btn btn-primary btn-stripe">About Us</NavLink>
		        </div>
		      </div>
		    </div>
		  </div>
		  <Footer/>
		</div>
	);
};

export default Contact;