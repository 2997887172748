import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';


class Header extends Component {
    constructor(props) {
        super(props);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.state = {active: false};
    }
    componentDidMount() {
       var menuType = 'desktop';
       $(window).on('load resize', function() {
            var currMenuType = 'desktop';
            // alert(1);
            if ( matchMedia( 'only screen and (max-width: 991px)' ).matches ) {
                currMenuType = 'mobile';
            }

            if ( currMenuType !== menuType ) {
                menuType = currMenuType;

                if ( currMenuType === 'mobile' ) {
                    var $mobileMenu = $('#mainnav').attr('id', 'mainnav-mobi').hide();
                    var hasChildMenu = $('#mainnav-mobi').find('li:has(ul)');

                    $('#header').after($mobileMenu);
                    hasChildMenu.children('ul').hide();
                    hasChildMenu.children('a').after('<span class="btn-submenu"></span>');
                    $('.btn-menu').removeClass('active');
                } else {
                    var $desktopMenu = $('#mainnav-mobi').attr('id', 'mainnav').removeAttr('style');

                    $desktopMenu.find('.submenu').removeAttr('style');
                    $('#header').find('.nav-wrap').append($desktopMenu);
                    $('.btn-submenu').remove();
                }
            }
        });
       /*$('.btn-menu').on('click', function() {         
            $('#mainnav-mobi').slideToggle(300);
            $(this).toggleClass('active');
        });*/
       $(document).on('click', '#mainnav-mobi li .btn-submenu', function(e) {
            $(this).toggleClass('active').next('ul').slideToggle(300);
            e.stopImmediatePropagation()
        });
       
       var hd_height = $('#header').height();           
        $(window).on('load scroll', function(){
            if ( $(window).scrollTop() > hd_height + 30 ) {
                $('#header').addClass('downscrolled');                      
            } else {                    
                $('#header').removeClass('downscrolled');                   
            }
            if( $(window).scrollTop() > 300 ) {
                $('#header').addClass('upscrolled');                    
            } else {
                $('#header').removeClass('upscrolled');                    
            }
        });    
    }
    onMenuClick(e) {
        var menuType = 'desktop';
        var currMenuType = 'desktop';
            // alert(1);
            if ( matchMedia( 'only screen and (max-width: 991px)' ).matches ) {
                currMenuType = 'mobile';
            }

            if ( currMenuType !== menuType ) {
                menuType = currMenuType;

                if ( currMenuType === 'mobile' ) {
                    var $mobileMenu = $('#mainnav').attr('id', 'mainnav-mobi').hide();
                    var hasChildMenu = $('#mainnav-mobi').find('li:has(ul)');

                    $('#header').after($mobileMenu);
                    hasChildMenu.children('ul').hide();
                    hasChildMenu.children('a').after('<span class="btn-submenu"></span>');
                    $('.btn-menu').removeClass('active');
                } else {
                    var $desktopMenu = $('#mainnav-mobi').attr('id', 'mainnav').removeAttr('style');

                    $desktopMenu.find('.submenu').removeAttr('style');
                    $('#header').find('.nav-wrap').append($desktopMenu);
                    $('.btn-submenu').remove();
                }
            }

            $('#mainnav-mobi').slideToggle(300);
            $(this).toggleClass('active');
    }
    render() {
        return (
          <header id="header" className="header clearfix"> 
            <div className="header-wrap clearfix">
                <div className="container">
                    <div id="logo" className="logo">
                        <NavLink to="/"><img src={require("../images/header-logo.png")}  alt="CoEdge Header Logo"/></NavLink>
                    </div>
                    <div className="nav-wrap">
                        <div className="btn-menu" onClick={this.onMenuClick.bind(this)}></div>
                        <nav id="mainnav" className="mainnav">
                            <ul className="menu"> 
                                <li data-id="home" className="home" ><NavLink to="/">Home</NavLink></li>
                                <li data-id="about"><NavLink to="/about">About us</NavLink></li>
                                <li data-id="services"><NavLink to="/services">Services</NavLink></li>
                                <li data-id="career"><NavLink to="/career">Careers</NavLink></li>                                
                          </ul>
                        </nav>
                    </div>
                </div> 
            </div>
          </header>
        );
    }
}

export default Header;
