import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

import '../stylesheets/bootstrap.css';
import '../stylesheets/style.css';
import '../stylesheets/responsive.css';
import '../stylesheets/colors/color1.css';
import '../stylesheets/animate.css';
import '../icon/apple-touch-icon-48-precomposed.png';
import '../icon/apple-touch-icon-32-precomposed.png';
import '../icon/favicon.png';

class Career extends Component {
  constructor(props) {
    super(props);
    this.onItemClick = this.onItemClick.bind(this);
    this.state = {
      clicked: [true,false,false,false]
    };
  }
  onItemClick(event) {
  	this.setState = {
      clicked: [false,false,false,false]
    };
    var idx = event.currentTarget.dataset['id'];
    var tmp = this.state.clicked;
    tmp[idx] = true;
    this.setState({clicked: tmp});
  }
  render() {
    return (
      <div className="boxed">          
          <Header/>
          <div className="page-title parallax parallax1">
              <div className="overlay"></div>
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">                    
                          <div className="page-title-heading">
                              <h1 className="title">Career</h1>
                          </div>
                      </div>
                  </div>
              </div>       
          </div>
          <section className="flat-row pad-top90px pad-bottom70px">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="title-section">
                              <h4 className="title text-left">Join our creative team!</h4>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-md-12">
                          <div className="flat-accordion">
                              <div className="flat-toggle">
                                  <div data-id="0" className={ this.state.clicked[0] ? "toggle-title active" : "toggle-title"} onClick={this.onItemClick.bind(this)} >Sr. BIM Coordinator</div>
                                  <div className={ this.state.clicked[0] ? "toggle-content display-block" : "display-none"}>
                                      <p><strong>Qualification:</strong> B.E. / Diploma Civil Engineering or Architect</p>
                                      <p><strong>Experience:</strong> Minimum 3 years of experience on Revit Architecture / MEP Scan to BIM projects. Experience of handling overseas project is preferred.</p>
                                      <p><strong>Location:</strong> Ahmedabad (Gujarat)</p>
                                      <p><strong>Key Responsibilities:</strong> Understanding customer requirements and divide work among the team.</p>                                
                                      <ul>
                                          <li><i className=" fa fa-check"></i> Coordinate the project with a team of 5-6 BIM modelers</li>
                                          <li><i className=" fa fa-check"></i> Ensure correct delivery to the client – On Time In Full</li>
                                          <li><i className=" fa fa-check"></i> Engage with client QC team and make necessary iterations to complete the final delivery</li>
                                      </ul>
                                  </div>
                              </div>
                              <div className="flat-toggle">
                                  <div data-id="1" className={ this.state.clicked[1] ? "toggle-title active" : "toggle-title"} onClick={this.onItemClick.bind(this)}>Jr. BIM Engineer</div>
                                  <div className={ this.state.clicked[1] ? "toggle-content display-block" : "display-none"}>
                                      <div className="info">                                  
                                          <p><strong>Qualification:</strong> B.E. / Diploma Civil Engineering or Architect</p>
                                          <p><strong>Experience:</strong> Minimum 1 year of experience on Revit Architecture / MEP.</p>
                                          <p><strong>Location:</strong> Ahmedabad (Gujarat)</p>
                                          <p><strong>Key Responsibilities:</strong> To complete the tasks assigned by Sr. BIM Coordinator</p>
                                          <ul>
                                              <li><i className=" fa fa-check"></i> To prepare 3D model based on point cloud data and photo tour</li>
                                              <li><i className=" fa fa-check"></i> To do the necessary iterations based on QC inputs</li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                              <div className="flat-toggle">
                                  <div data-id="2" className={ this.state.clicked[2] ? "toggle-title active" : "toggle-title"} onClick={this.onItemClick.bind(this)}>Trainee BIM Engineer</div>
                                  <div className={ this.state.clicked[2] ? "toggle-content display-block" : "display-none"}>
                                      <div className="info">
                                          <p><strong>Qualification:</strong> B.E. / Diploma Civil Engineering or Architect</p>
                                          <p><strong>Experience:</strong> Freshers can apply. Knowledge of Revit Architecture / MEP is must.</p>
                                          <p><strong>Location:</strong> Ahmedabad (Gujarat)</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="flat-toggle">
                                  <div data-id="3" className={ this.state.clicked[3] ? "toggle-title active" : "toggle-title"} onClick={this.onItemClick.bind(this)} >QC Engineer Revit</div>
                                  <div className={ this.state.clicked[3] ? "toggle-content display-block" : "display-none"}>
                                      <div className="info"> 
                                          <p><strong>Qualification:</strong> B.E. / Diploma Civil Engineering or Architect</p>
                                          <p><strong>Experience:</strong> Minimum 2 years of Experience of Revit Architecture / MEP project.</p>
                                          <p><strong>Experience</strong> as Quality Check Engineer on Revit is preferred.</p>
                                          <p><strong>Location:</strong> Ahmedabad (Gujarat)</p>
                                          <p><strong>Key Responsibilities:</strong> To ensure conformity of each deliverables to the client needs.</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <div className="flat-row row-contact-us">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="flat-contact-us">
                              <h1>Your Trusted Partner for Growth</h1>
                              <p>Passion | Experience | Skills</p>
                          </div>
                          <NavLink to="/about" className="btn btn-primary btn-stripe">About Us</NavLink>
                      </div>
                  </div>
              </div>
          </div>
         <Footer/>
      </div>
    );
  }
}

export default Career;
